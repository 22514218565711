@mixin non-mobile {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin phone {
  @media screen and (max-width: 767px) {
    @content;
  }
}

@mixin portrait {
  @media screen and (max-aspect-ratio: 1/1) {
    @content;
  }
}

@mixin touch {
  @media screen and (any-pointer: coarse) {
    @content;
  }
}

@mixin mouse {
  @media screen and (any-pointer: fine) {
    @content;
  }
}

@font-face {
  font-family: "Markazi Text";
  font-weight: 500;
  src: 
    url("../fonts/MarkaziText-Medium.woff") format("woff"),
    url("../fonts/MarkaziText-Medium.woff2") format("woff2");
}

$bg: hsl(0,0%,96%);
$pattern: url(../images/pattern.svg) repeat 100%;
$highlight: hsl(44,85%,59%);
$layer-bg: hsla(0,0%,100%,1);
$layer-hover: hsla(0,0%,100%,1);
$border: hsla(0,0%,0%,.06);
$color: black;
$secondary: lighten($color, 50%);
$box-bg: $layer-bg;
$sans: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$headline: "Markazi Text", serif;

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap; /* 1 */
}

%clickable {
  cursor: pointer;
  user-select: none;
  touch-action: manipulation;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.secretPath {
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  z-index: 5;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $sans;
  font-size: 16px;
  line-height: 1.4;
  color: $color;
  background: $bg;
  
  @media screen and (min-width: 1440px) {
    font-size: calc(18px + .1vw);
  }
}

p,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1em;
  max-width: 50em;
}

ol,
ul {
  padding-left: 1em;
}

strong {
  font-weight: 600;
}

.more {
  @extend %clickable;
  color: $highlight;
  text-decoration: underline;
  
  ~ * {
    display: none !important;
  }
}

.image-ratio,
.icon-ratio {
  position: relative;
  
  img,
  svg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

svg {
  fill: currentColor;
  vertical-align: middle;
  
  use {
    pointer-events: none;
  }
}

h1,
h2,
h3,
.quote {
  font-family: $headline;
  font-weight: 500;
}

a:link {
  color: $highlight;
}

h1 {
  text-align: center;
  border-bottom: 1px solid $border;
  margin: 2rem 0;
  font-size: 4em;
  line-height: 1.5;
  
  @include phone {
    font-size: 3em;
    margin: 1rem 0;
  }
}

h2 {
  font-size: 2.4em;
  margin: 0 0 .4em;
  line-height: 1;
}

h3 {
  font-size: 1.75em;
  line-height: .9;
}

ul {
  list-style: none;
  padding-left: 0;
  
  li {
    margin: .3em 0;
    
    &:before {
      content: "";
      background: url(../images/checkmark.svg) no-repeat;
      background-size: cover;
      width: 1em;
      height: 1em;
      margin: .2em .5em 0 0;
      vertical-align: top;
      display: inline-block;
    }
  }
}

header {
  text-align: center;
  overflow: hidden;
  text-align: center;
  padding: 2em 0 0;
  
  &.fullHeight {
    height: 100vh;
    padding: 3em 0;
    display: flex;
    flex-direction: column;
    
    @include portrait {
      height: auto;
    }
    
    .logo {
      flex: 1;
      
      @include portrait {
        flex-grow: 0;
        height: 50vh;
      }
    }
    
    img {
      height: 100%;
      width: auto;
      object-fit: contain;
    }

    menu {
      margin: 3em 0;
    }
  }

  .logo {
    display: inline-block;
  }

  img {
    width: 200px;
    height: 200px;
  }
}

[data-template="default"] {
  .section {
    padding-top: 0;
  }
}

.quote {
  font-size: 1.8em;
  line-height: 1.2;
  text-align: center;
  max-width: 25em;
  margin: 0 auto;
  padding: 0 1em;

  p:last-child {
    margin-bottom: 0;
  }

  @include phone {
    font-size: 1.6em;
  }

  .logo + & {
    margin-top: 1em;
  }
}

menu {
  text-align: center;
  background: $layer-bg;
  
  .container {
    padding: 0;
    
    @include phone {
      display: flex;
      padding: 0 1em;
    }
  }
  
  a {
    text-decoration: none;
    padding: .4em 1.5em;
    display: inline-block;
    font-size: 1em;
    letter-spacing: .02em;
    text-transform: uppercase;
    font-weight: bold;
    color: inherit;
    white-space: nowrap;

    &:first-child {
      margin-left: -3em;

      @include phone {
        margin-left: 0;
      }
    }

    &:link {
      color: inherit;
    }
    
    &:hover {
      background: $layer-hover;
      color: $highlight;
      
      @include phone {
        background: none;
      }
    }
    
    @include phone {
      padding: .75em 0;
      flex: 1 1 auto;
      font-size: .9em;
    }
  }
}

.container {
  max-width: 75em;
  margin: 0 auto;
  padding: 0 2em;
  
  @include phone {
    padding: 0 .5em;
  }
}

.section {
  padding: 0 0 4em;
  overflow: hidden;
  
  @include phone {
    padding: 0 0 1em;
  }
}

.boxes {
  margin: 0 auto;
  max-width: calc(75% - 2em);

  @include phone {
    max-width: 100%;
  }

  .box {
    flex: 1;
    padding: 1.55em 2em 1.7em;
    background: $box-bg;
    margin-bottom: 2em;

    @include phone {
      margin-bottom: 1em;
      padding: 1.25em 1.4em;
    }
    
    &:last-child {
      margin-right: 0;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }
}

.cooperations {
  margin: 1.5em 0 1em;
}

.cooperation {
  padding: .3em .5em;
  display: table;
  
  &.highlight {
    animation: highlight 1s;
  }
  
  &:last-child {
    margin-bottom: 0;
  }
  
  &-icon {
    width: 1.3em;
    height: 1.3em;
    line-height: 1.3em;
    margin-right: .25em;
    text-align: center;
    text-decoration: none;
    border-radius: 100%;
    font-weight: bold;
    display: inline-block;
    
    .artist & {
      margin-right: .33em;
    }
  }
}

@keyframes highlight {
  50% {
    background: white;
  }
  to {
    background: none;
  }
}

.artists {
  display: grid;
  grid-gap: 2em;
  grid-template-columns: repeat(auto-fill, minmax(15em, 1fr));
  
  @include phone {
    grid-template-columns: repeat(auto-fill, minmax(138px, 1fr));
    grid-gap: 1em;
    font-size: 12px;
    margin: 0 .5em;
  }

  [data-layout="small"] & {
    grid-template-columns: repeat(auto-fill, minmax(20em, 1fr));
  }

  &-group {
    margin-bottom: 5em;

    h2 {
      margin-left: .5rem;
      margin-right: .5rem;
    }
  }

  &-more {
    grid-column: 1 / -1;
    padding: .85em;
    text-align: center;
    text-decoration: none;
    color: $highlight;
    background: $layer-bg;
    
    &:hover {
      color: $highlight;
    }
  }
}

.artist {
  background: $box-bg;
  display: flex;
  flex-direction: column;

  [data-layout="small"] & {
    flex-direction: row;
  }
    
  a:link {
    color: inherit;
  }
  
  &-photo {
    background: $layer-hover;
    position: relative;

    [data-layout="small"] & {
      width: 9em;
      height: 9em;

      @include phone {
        width: 7em;
        height: 7em;
      }
    }

    img {
      width: 100%;
      height: auto;
      vertical-align: middle;
    }
    
    &-credit {
      position: absolute;
      right: 0;
      bottom: 0;
      font-size: 9px;
      padding: 5px;
      text-decoration: none;
      text-shadow: 1px 1px 3px hsla(0,0%,0%,.3);
      color: hsla(0,0%,100%,.85) !important;
      
      &:hover {
        color: white !important;
        text-shadow: 1px 1px 3px hsla(0,0%,0%,.75);
      }

      @include phone {
        font-size: 7px;
      }
    }
  }

  &-detail {
    padding: .5em .7em .6em;
    flex: 1;
    display: flex;
    flex-direction: column;
    
    &-meta {
      display: flex;
      flex: 1;

      [data-layout="small"] & {
        flex-direction: column;
      }
      
      @include phone {
        flex-direction: column;

        [data-layout="tiny"] & {
          flex-direction: row;
        }
      }
    }
  }

  sup {
    font-size: .65em;
  }

  &-tags {
    margin-left: .5em;
    margin-right: .2em;
    margin-bottom: -.15em;
    align-self: flex-end;
    display: flex;
    align-items: center;

    [data-layout="small"] & {
      margin-top: .5em;
    }
    
    @include phone {
      margin-top: .7em;
      align-items: initial;

      [data-layout="tiny"] & {
        margin-top: 0;
      }
    }
  }

  &-copyright-scope {
    & + & {
      margin-left: .65em;
    }
  }

  &-secondary {
    margin-right: auto;
    margin-top: .15em;
    color: $secondary;
    font-size: .9em;
    line-height: 1.2;

    [data-layout="small"] & {
      flex: 1;
    }
    
    @include phone {
      margin-bottom: auto;
    }

    a:visited {
      color: hsl(271,36%,50%);
    }
  }

  &-service {
    a + & {
      margin-top: 0.3em;
    }
  }
}

.team {
  margin: 0 auto;
  
  &-row {
    display: flex;
    justify-content: center;
    
    @include phone {
      display: block;
    }
  }
}
  
.person {
  font-style: normal;
  display: flex;
  flex-direction: column;
  margin: 0 1em 2em;
  flex-shrink: 0;
  width: calc(25% - 2em);
  
  @include phone {
    width: calc(100% - 2em);
  }

  &-photo {
    padding-bottom: 100%;
    position: relative;
    background: hsl(35,50%,92%);
    
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    &-backside {
      display: none;
    }

    &:hover {
      .person-photo-frontside {
        display: none;
      }

      .person-photo-backside {
        display: block;
      }
    }
  }

  &-details {
    padding: .8em 1em .8em;
    background: $box-bg;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  h3 {
    display: inline;
  }

  .position {
    color: $secondary;
    font-size: .85em;
    font-style: italic;
    margin-left: .4em;
    margin-top: -.3em;
    vertical-align: middle;
    display: inline-block;
    
    &:before {
      content: ' – ';
    }
  }

  .work {
    color: $secondary;
    margin-bottom: auto;
  }

  a {
    color: inherit;
  }

  .email {
    margin: .6666em 0 0;
  }

  .location {
    font-size: .75em;
    margin: .5em 0 .75em;
    font-style: italic;
  }
      
  .hobbies {
    font-size: 1.33em;
  }
}

.footer {
  border-top: 1px solid $border;
  padding: 2em 0;
  text-align: right;
  
  @include phone {
    text-align: center;
  }
  
  a {
    font-weight: normal;
    font-size: .85em;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: .06em;
    
    &:visited,
    &:link {
      color: inherit;
    }
  }
}
