@charset "UTF-8";
@font-face {
  font-family: "Markazi Text";
  font-weight: 500;
  src: url("../fonts/MarkaziText-Medium.woff") format("woff"), url("../fonts/MarkaziText-Medium.woff2") format("woff2"); }

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  /* 1 */ }

.more {
  cursor: pointer;
  user-select: none;
  touch-action: manipulation; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

.secretPath {
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  z-index: 5; }

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  line-height: 1.4;
  color: black;
  background: whitesmoke; }
  @media screen and (min-width: 1440px) {
    html {
      font-size: calc(18px + .1vw); } }

p,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1em;
  max-width: 50em; }

ol,
ul {
  padding-left: 1em; }

strong {
  font-weight: 600; }

.more {
  color: #efc03e;
  text-decoration: underline; }
  .more ~ * {
    display: none !important; }

.image-ratio,
.icon-ratio {
  position: relative; }
  .image-ratio img,
  .image-ratio svg,
  .icon-ratio img,
  .icon-ratio svg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }

svg {
  fill: currentColor;
  vertical-align: middle; }
  svg use {
    pointer-events: none; }

h1,
h2,
h3,
.quote {
  font-family: "Markazi Text", serif;
  font-weight: 500; }

a:link {
  color: #efc03e; }

h1 {
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  margin: 2rem 0;
  font-size: 4em;
  line-height: 1.5; }
  @media screen and (max-width: 767px) {
    h1 {
      font-size: 3em;
      margin: 1rem 0; } }

h2 {
  font-size: 2.4em;
  margin: 0 0 .4em;
  line-height: 1; }

h3 {
  font-size: 1.75em;
  line-height: .9; }

ul {
  list-style: none;
  padding-left: 0; }
  ul li {
    margin: .3em 0; }
    ul li:before {
      content: "";
      background: url(../images/checkmark.svg) no-repeat;
      background-size: cover;
      width: 1em;
      height: 1em;
      margin: .2em .5em 0 0;
      vertical-align: top;
      display: inline-block; }

header {
  text-align: center;
  overflow: hidden;
  text-align: center;
  padding: 2em 0 0; }
  header.fullHeight {
    height: 100vh;
    padding: 3em 0;
    display: flex;
    flex-direction: column; }
    @media screen and (max-aspect-ratio: 1 / 1) {
      header.fullHeight {
        height: auto; } }
    header.fullHeight .logo {
      flex: 1; }
      @media screen and (max-aspect-ratio: 1 / 1) {
        header.fullHeight .logo {
          flex-grow: 0;
          height: 50vh; } }
    header.fullHeight img {
      height: 100%;
      width: auto;
      object-fit: contain; }
    header.fullHeight menu {
      margin: 3em 0; }
  header .logo {
    display: inline-block; }
  header img {
    width: 200px;
    height: 200px; }

[data-template="default"] .section {
  padding-top: 0; }

.quote {
  font-size: 1.8em;
  line-height: 1.2;
  text-align: center;
  max-width: 25em;
  margin: 0 auto;
  padding: 0 1em; }
  .quote p:last-child {
    margin-bottom: 0; }
  @media screen and (max-width: 767px) {
    .quote {
      font-size: 1.6em; } }
  .logo + .quote {
    margin-top: 1em; }

menu {
  text-align: center;
  background: white; }
  menu .container {
    padding: 0; }
    @media screen and (max-width: 767px) {
      menu .container {
        display: flex;
        padding: 0 1em; } }
  menu a {
    text-decoration: none;
    padding: .4em 1.5em;
    display: inline-block;
    font-size: 1em;
    letter-spacing: .02em;
    text-transform: uppercase;
    font-weight: bold;
    color: inherit;
    white-space: nowrap; }
    menu a:first-child {
      margin-left: -3em; }
      @media screen and (max-width: 767px) {
        menu a:first-child {
          margin-left: 0; } }
    menu a:link {
      color: inherit; }
    menu a:hover {
      background: white;
      color: #efc03e; }
      @media screen and (max-width: 767px) {
        menu a:hover {
          background: none; } }
    @media screen and (max-width: 767px) {
      menu a {
        padding: .75em 0;
        flex: 1 1 auto;
        font-size: .9em; } }

.container {
  max-width: 75em;
  margin: 0 auto;
  padding: 0 2em; }
  @media screen and (max-width: 767px) {
    .container {
      padding: 0 .5em; } }

.section {
  padding: 0 0 4em;
  overflow: hidden; }
  @media screen and (max-width: 767px) {
    .section {
      padding: 0 0 1em; } }

.boxes {
  margin: 0 auto;
  max-width: calc(75% - 2em); }
  @media screen and (max-width: 767px) {
    .boxes {
      max-width: 100%; } }
  .boxes .box {
    flex: 1;
    padding: 1.55em 2em 1.7em;
    background: white;
    margin-bottom: 2em; }
    @media screen and (max-width: 767px) {
      .boxes .box {
        margin-bottom: 1em;
        padding: 1.25em 1.4em; } }
    .boxes .box:last-child {
      margin-right: 0; }
    .boxes .box p:last-child {
      margin-bottom: 0; }

.cooperations {
  margin: 1.5em 0 1em; }

.cooperation {
  padding: .3em .5em;
  display: table; }
  .cooperation.highlight {
    animation: highlight 1s; }
  .cooperation:last-child {
    margin-bottom: 0; }
  .cooperation-icon {
    width: 1.3em;
    height: 1.3em;
    line-height: 1.3em;
    margin-right: .25em;
    text-align: center;
    text-decoration: none;
    border-radius: 100%;
    font-weight: bold;
    display: inline-block; }
    .artist .cooperation-icon {
      margin-right: .33em; }

@keyframes highlight {
  50% {
    background: white; }
  to {
    background: none; } }

.artists {
  display: grid;
  grid-gap: 2em;
  grid-template-columns: repeat(auto-fill, minmax(15em, 1fr)); }
  @media screen and (max-width: 767px) {
    .artists {
      grid-template-columns: repeat(auto-fill, minmax(138px, 1fr));
      grid-gap: 1em;
      font-size: 12px;
      margin: 0 .5em; } }
  [data-layout="small"] .artists {
    grid-template-columns: repeat(auto-fill, minmax(20em, 1fr)); }
  .artists-group {
    margin-bottom: 5em; }
    .artists-group h2 {
      margin-left: .5rem;
      margin-right: .5rem; }
  .artists-more {
    grid-column: 1 / -1;
    padding: .85em;
    text-align: center;
    text-decoration: none;
    color: #efc03e;
    background: white; }
    .artists-more:hover {
      color: #efc03e; }

.artist {
  background: white;
  display: flex;
  flex-direction: column; }
  [data-layout="small"] .artist {
    flex-direction: row; }
  .artist a:link {
    color: inherit; }
  .artist-photo {
    background: white;
    position: relative; }
    [data-layout="small"] .artist-photo {
      width: 9em;
      height: 9em; }
      @media screen and (max-width: 767px) {
        [data-layout="small"] .artist-photo {
          width: 7em;
          height: 7em; } }
    .artist-photo img {
      width: 100%;
      height: auto;
      vertical-align: middle; }
    .artist-photo-credit {
      position: absolute;
      right: 0;
      bottom: 0;
      font-size: 9px;
      padding: 5px;
      text-decoration: none;
      text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
      color: rgba(255, 255, 255, 0.85) !important; }
      .artist-photo-credit:hover {
        color: white !important;
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.75); }
      @media screen and (max-width: 767px) {
        .artist-photo-credit {
          font-size: 7px; } }
  .artist-detail {
    padding: .5em .7em .6em;
    flex: 1;
    display: flex;
    flex-direction: column; }
    .artist-detail-meta {
      display: flex;
      flex: 1; }
      [data-layout="small"] .artist-detail-meta {
        flex-direction: column; }
      @media screen and (max-width: 767px) {
        .artist-detail-meta {
          flex-direction: column; }
          [data-layout="tiny"] .artist-detail-meta {
            flex-direction: row; } }
  .artist sup {
    font-size: .65em; }
  .artist-tags {
    margin-left: .5em;
    margin-right: .2em;
    margin-bottom: -.15em;
    align-self: flex-end;
    display: flex;
    align-items: center; }
    [data-layout="small"] .artist-tags {
      margin-top: .5em; }
    @media screen and (max-width: 767px) {
      .artist-tags {
        margin-top: .7em;
        align-items: initial; }
        [data-layout="tiny"] .artist-tags {
          margin-top: 0; } }
  .artist-copyright-scope + .artist-copyright-scope {
    margin-left: .65em; }
  .artist-secondary {
    margin-right: auto;
    margin-top: .15em;
    color: gray;
    font-size: .9em;
    line-height: 1.2; }
    [data-layout="small"] .artist-secondary {
      flex: 1; }
    @media screen and (max-width: 767px) {
      .artist-secondary {
        margin-bottom: auto; } }
    .artist-secondary a:visited {
      color: #8152ad; }
  a + .artist-service {
    margin-top: 0.3em; }

.team {
  margin: 0 auto; }
  .team-row {
    display: flex;
    justify-content: center; }
    @media screen and (max-width: 767px) {
      .team-row {
        display: block; } }

.person {
  font-style: normal;
  display: flex;
  flex-direction: column;
  margin: 0 1em 2em;
  flex-shrink: 0;
  width: calc(25% - 2em); }
  @media screen and (max-width: 767px) {
    .person {
      width: calc(100% - 2em); } }
  .person-photo {
    padding-bottom: 100%;
    position: relative;
    background: #f5ece0; }
    .person-photo img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%; }
    .person-photo-backside {
      display: none; }
    .person-photo:hover .person-photo-frontside {
      display: none; }
    .person-photo:hover .person-photo-backside {
      display: block; }
  .person-details {
    padding: .8em 1em .8em;
    background: white;
    flex: 1;
    display: flex;
    flex-direction: column; }
  .person h3 {
    display: inline; }
  .person .position {
    color: gray;
    font-size: .85em;
    font-style: italic;
    margin-left: .4em;
    margin-top: -.3em;
    vertical-align: middle;
    display: inline-block; }
    .person .position:before {
      content: ' – '; }
  .person .work {
    color: gray;
    margin-bottom: auto; }
  .person a {
    color: inherit; }
  .person .email {
    margin: .6666em 0 0; }
  .person .location {
    font-size: .75em;
    margin: .5em 0 .75em;
    font-style: italic; }
  .person .hobbies {
    font-size: 1.33em; }

.footer {
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  padding: 2em 0;
  text-align: right; }
  @media screen and (max-width: 767px) {
    .footer {
      text-align: center; } }
  .footer a {
    font-weight: normal;
    font-size: .85em;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: .06em; }
    .footer a:visited, .footer a:link {
      color: inherit; }
